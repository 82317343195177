import './App.css';
import React, { useEffect, useState } from 'react';
// import { getDatabase, off, ref, onValue } from "firebase/database";

function App() {
  const [data, setData] = useState(null);
  const [sn, setSn] = useState(null);
  const [snName, setSnName] = useState(null);
  const [imageUrls, setImageUrls] = useState({
    '633': [],
    '830': [],
    '1072': []
  });

  useEffect(() => {
    const url = window.location.href;
    console.log("url", url);

    const paramStartIndex = url.indexOf('d=');
    if (paramStartIndex === -1) {
      console.log("No 'd=' parameter found in the URL.");
    } else {
      const param = url.slice(paramStartIndex + 2);
      console.log("param :", param);

      if (param.length >= 14) {
        const hexString = param.substring(0, 14);
        const decimalValue = parseInt(hexString, 16);
        console.log(decimalValue);
        setSnName(decimalValue);
        const snValue = decimalValue.toString();
        setSn(hexString);
        console.log("sn", sn);
      } else {
        console.log("Parameter is shorter than expected.");
      }
    }
  }, []);

  useEffect(() => {
    if (sn) {
      const fetchData = () => {
        const url = `https://api-nfc.tapnex.io/nfc/query?sn=${sn}`;
        console.log('nihao' ,url);
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(responseData => {
            if (responseData.code === 0) {
              setData(responseData.data);
              console.log("Response data:", responseData.data);
            } else {
              throw new Error(responseData.message || 'Request failed');
            }
          })
          .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
          });
      }
      fetchData();
    }
  }, [sn]);

  useEffect(() => {
    console.log("sn in separate useEffect", sn);
  }, [sn]);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  useEffect(() => {
    fetch('/images.txt')
      .then(response => response.text())
      .then(text => {
        const urls = {
          '633': [],
          '830': [],
          '1072': []
        };
        text.split('\n').forEach(line => {
          const [, url1072, url633, url830] = line.split('\t');
          if (url633) urls['633'].push(url633.trim());
          if (url830) urls['830'].push(url830.trim());
          if (url1072) urls['1072'].push(url1072.trim());
        });
        
        setImageUrls(urls);
      })
      .catch(error => console.error('Error loading images:', error));
  }, []);

  const getRandomImage = (wavelength) => {
    try {
      if (!imageUrls[wavelength] || imageUrls[wavelength].length === 0) {
        console.warn(`No images available for wavelength ${wavelength}`);
        return '';
      }
      const randomIndex = Math.floor(Math.random() * imageUrls[wavelength].length);
      return imageUrls[wavelength][randomIndex];
    } catch (error) {
      console.error(`Error getting random image for wavelength ${wavelength}:`, error);
      return '';
    }
  };

  // 添加一个函数来检查图片是否存在
  const checkImageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  // 修改 wavelengthImages 的定义，使用 useEffect 来处理图片检查
  const [finalImages, setFinalImages] = useState([
    { name: 'Red 633nm', src: '' },
    { name: 'Near-Infrared 830nm', src: '' },
    { name: 'Deep Near-Infrared 1072nm', src: '' }
  ]);

  useEffect(() => {
    if (!data) return;

    const checkAndSetImages = async () => {
      const images = await Promise.all([
        {
          name: 'Red 633nm',
          src: data.w633,
          wavelength: '633'
        },
        {
          name: 'Near-Infrared 830nm',
          src: data.w830,
          wavelength: '830'
        },
        {
          name: 'Deep Near-Infrared 1072nm',
          src: data.w1072,
          wavelength: '1072'
        }
      ].map(async (img) => {
        const exists = await checkImageExists(img.src);
        return {
          name: img.name,
          src: exists ? img.src : getRandomImage(img.wavelength)
        };
      }));

      setFinalImages(images);
    };

    checkAndSetImages();
  }, [data, imageUrls]); // 依赖于 data 和 imageUrls

  // if (!data || Object.keys(data).length === 0) {
  //   return <div className="mx-auto flex items-center justify-center h-screen bg-white text-black-700 text-2xl font-bold font-lato">
  //     Loading...
  //   </div>
  // }

  if (!data || !data.batchNo) {
    return <div className="mx-auto flex items-center justify-center h-screen bg-white text-black-700 text-2xl font-bold font-lato">
      <div className="text-red-600 text-center">
        <p>No product information found for this NFC card.</p>
        <p>NFC Number: {snName}</p>
      </div>
    </div>
  }

  return (
    <div className="mx-auto max-w-[550px] bg-white text-black font-lato">
      {/* Header */}
      <header className="text-center py-8">
        <a href="https://www.currentbody.com/collections/currentbody-skin"><img className="w-[233px] h-[41px] mx-auto mb-6" alt="CurrentBody Skin Logo" src="https://www.currentbody.com/cdn/shop/t/1256/assets/currentbody-skin_1024x1024.png?v=170181410224247424011724169866" /></a>
        <h1 className="text-4xl font-bold mb-4">Veritace® Testing & Traceability</h1>
        <img className="w-[331px] h-[246px] mx-auto mb-6" alt="LED Neck&Dec" src="currentbodymask.png" />
        <p className="text-3xl font-bold mb-2">LED Neck&Dec:<br></br>Series 2</p>
        <p className="text-sm mb-4">NFC card number: {snName}</p>
      </header>

      {/* Product Traceability */}
      <section className="px-6 mb-12 bg-gray-50 py-8">
        <h2 className="text-3xl font-bold mb-8">PRODUCT TRACEABILITY</h2>
        <div className="relative pl-12 border-l-2 border-black">
          {[
            { label: 'Component sourcing', date: data.cs || "Loading..." },
            { label: 'LED selection process', date: data.lsp || "Loading..." },
            { label: 'Component transfer to manufacturing', date: data.cttm || "Loading..." },
            { label: 'Product build', date: data.pd || "Loading..." },
            { label: 'Final product quality testing', date: data.fpqt || "Loading..." },
            { label: 'Ready for global distribution', date: data.rfgd || "Loading..." },
          ].map((item, index) => (
            <div key={index} className="mb-8 relative">
              <div className="absolute -left-[21px] top-1 w-6 h-6 bg-black rounded-full"></div>
              <div className="ml-4">
                <p className="text-lg font-bold">{item.label}</p>
                <p className="text-base">{item.date}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Spectroradiometer Precision Testing */}
      <section className="px-6 mb-12">
        <h2 className="text-3xl font-bold mb-8">SPECTRORADIOMETER PRECISION TESTING</h2>
        {finalImages.map((wavelength, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl mb-2">{wavelength.name}</h3>
            <p className="text-green-600 text-xl font-bold mb-2">PASSED √</p>
            <img
              className="w-full h-auto"
              src={wavelength.src}
              alt={`${wavelength.name} graph`}
            />
          </div>
        ))}
      </section>

      {/* Testing Results */}
      <section className="px-6 mb-12 bg-gray-50 py-8">
        <h2 className="text-3xl font-bold mb-8">TESTING RESULTS</h2>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-black text-white">
              <th className="p-3 text-left text-lg">Test</th>
              <th className="p-3 text-left text-lg">Status</th>
              <th className="p-3 text-left text-lg">Equipment</th>
            </tr>
          </thead>
          <tbody>
            {[
              { test: 'LED placement check', equipment: 'Imager' },
              { test: 'Device power output', equipment: 'Multimeter' },
              { test: 'Battery capacity', equipment: 'Battery capacity division system' },
              { test: 'Temperature', equipment: 'Humidity temperature meter' },
              { test: 'Standard and Electrical Safety', equipment: 'Third party laboratory' },
            ].map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                <td className="p-3 text-base">{item.test}</td>
                <td className="p-3 text-base font-bold whitespace-nowrap">
                  PASSED <span className="text-green-600 text-xl">√</span>
                </td>
                <td className="p-3 text-base">{item.equipment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Product Information */}
      <section className="px-6 mb-12">
        <h2 className="text-3xl font-bold mb-8">CurrentBody Skin LED Neck&Dec: Series 2</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="font-bold">Production date</div>
          <div>{data.pd || "16th August 2024"}</div>
          <div className="font-bold">Battery capacity</div><div>2600mAh</div>
          <div className="font-bold">Dimensions</div><div>190 × 98 × 207 mm</div>
          <div className="font-bold">Treatment time</div><div>10 minutes (Auto-timer)</div>
          <div className="font-bold">Treatment protocol</div><div>3-5 times weekly</div>
          <div className="font-bold">Battery charging duration</div><div>4.5 hours</div>
          <div className="font-bold">Product operational time</div><div>10 uses per charge</div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black text-white py-8 text-center">

        <p className="mb-2">Learn more at</p>
        <a href="https://currentbody.com/veritace" className="underline text-xl">currentbody.com/veritace</a>
      </footer>
    </div>
  );
}

export default App;
